import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import {
  clearSubmissionStateAction,
  createTaskAction,
  useTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import {
  AssetType,
  CamTaskStatus,
  IAssociation,
  TaskType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AddTaskType, ReduxTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import LoadingButton from '@mui/lab/LoadingButton';
import { AddTaskFromSopTask } from './AddTaskFromSopTask';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { AddTaskForm } from '../AddTaskForm';
import { AssociateWithCurrentLeaseToggle } from '../AssociateWithCurrentLeaseToggle';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';

type AddTaskDialogProps = {
  open: boolean;
  onClose: () => void;
  assetId: string;
  associationType: AssetType;
  includeClosed: boolean;
  taskType?: AddTaskType;
  localSopAssociation?: IAssociation;
} & Omit<DialogProps, 'onClose' | 'onSubmit'>;

export const AddTaskDialog: FC<AddTaskDialogProps> = ({
  open,
  onClose,
  assetId,
  associationType,
  includeClosed,
  taskType,
  localSopAssociation,
  ...dialogProps
}) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const tasks = useTasks();
  const [newTask, setNewTask] = useState<ReduxTask | undefined>();

  useEffect(() => {
    if (open) {
      setNewTask({
        associatedId: assetId,
        assetType: associationType,
        name: '',
        description: '',
        status: CamTaskStatus.NotStarted,
        taskType: TaskType.Normal,
        associateWithCurrentLease: false,
      });
    }
  }, [open]);

  useEffect(() => {
    if (tasks.newTask.submitted) handleClose();
  }, [tasks.newTask.submitted]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    newTask &&
      dispatch(
        createTaskAction({
          includeClosed: includeClosed,
          body: {
            ...newTask,
            checklist: newTask.checklist?.filter((item) => item.name !== undefined).map((item) => item.name!) ?? [],
          },
        }),
      );
  };

  const handleChange = (value: ReduxTask) => {
    setNewTask(value);
  };

  const handleAssociateWithCurrentLease = () => {
    setNewTask({ ...newTask, associateWithCurrentLease: !newTask?.associateWithCurrentLease });
  };

  const handleClose = () => {
    setNewTask(undefined);
    onClose();
    dispatch(clearSubmissionStateAction());
  };

  return (
    <DialogLayout
      title={taskType === AddTaskType.NewTask ? 'Add Task' : 'Add Task From SOP Task'}
      open={open}
      sx={{ width: '100%' }}
      maxWidth="lg"
      PaperProps={{ sx: { width: '100%', maxWidth: 480 } }}
      onClose={handleClose}
      {...dialogProps}
    >
      {taskType === AddTaskType.NewTask ? (
        <form onSubmit={handleSubmit} autoComplete={'off'}>
          <DialogContent>
            <AddTaskForm onChange={handleChange} value={newTask} propertyId={selectedContext.propertyId} />
            <AssociateWithCurrentLeaseToggle
              value={newTask?.associateWithCurrentLease ?? false}
              onChange={handleAssociateWithCurrentLease}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <LoadingButton variant={'contained'} type={'submit'} loading={tasks.newTask.submitting}>
              Add Task
            </LoadingButton>
          </DialogActions>
        </form>
      ) : (
        <AddTaskFromSopTask
          onClose={onClose}
          includeClosed={includeClosed}
          associationType={associationType}
          associatedId={assetId}
          localSopTaskAssociation={localSopAssociation}
        />
      )}
    </DialogLayout>
  );
};
