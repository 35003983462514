import React, { FC, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import { Grow } from '@mui/material';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { AuthorizedClaims } from '../../../auth/AuthorizedClaims';
import { Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import { SuperAdminLinks } from './SuperAdminLinks';
import { tryFormatDate } from '../../utils/TryFormatDate';
import {
  ExternalTransactionStatus,
  JournalEntryStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Link from '@mui/material/Link';
import { getStatementLineDetailByJournalEntryIdAction } from '../redux/statementSlice';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { TableRow } from '../useStatementConfig';
import { dialogChildIsOpenAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

type PaymentStatusChipProps = {
  row: TableRow;
  value?: string;
  isTenantPortalView?: boolean;
  disableJournalEntryView?: boolean;
};
export const PaymentStatusChip: FC<PaymentStatusChipProps> = ({
  row,
  value,
  isTenantPortalView,
  disableJournalEntryView,
}) => {
  const dispatch = useDispatch();
  const chipRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const userClaims = useUser((u) => u.currentUser.value?.claims);
  const userRoles = useUser((u) => u.currentUser.value?.roles);
  const userIsTenant = userClaims?.some((c) => c.type === Claims.Tenant);
  const userIsSuperAdmin = userRoles?.some((c) => c === 'SUPERADMIN');
  const userHasPropertyClaims = userClaims?.find((c) => c.type !== Claims.Tenant);
  const userIsAccountant = userClaims?.some((c) => c.type === Claims.Accountant);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack ref={chipRef} direction={'row'} spacing={8} justifyItems={'center'}>
      {row.paymentStatus && row.paymentStatus !== ExternalTransactionStatus.InProcess && !isTenantPortalView && (
        <>
          <Chip
            sx={{ marginRight: 2 }}
            size="small"
            variant={'outlined'}
            color={'primary'}
            label={row.paymentStatus}
            onClick={handleClick}
          />
          {row.externalTransactions && (
            <Popper sx={{ zIndex: 999999 }} open={open} anchorEl={chipRef.current} transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box padding={2}>
                        <Typography variant="subtitle1">Payment Details</Typography>
                        {userIsTenant && row.sourceAccountLastFour && (
                          <Typography variant="subtitle2">Paid From: {row.sourceAccountLastFour}</Typography>
                        )}
                        {userHasPropertyClaims && row.releaseDate ? (
                          <Typography variant="subtitle2">Release Date: {tryFormatDate(row.releaseDate)}</Typography>
                        ) : userHasPropertyClaims && row.expectedReleaseDate ? (
                          <Typography variant="subtitle2">
                            Expected Release Date: {tryFormatDate(row.expectedReleaseDate)}
                          </Typography>
                        ) : (
                          <></>
                        )}
                        {userIsSuperAdmin && <SuperAdminLinks externalTransactions={row.externalTransactions} />}
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
        </>
      )}
      <Box
        sx={{
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          lineHeight: 'normal',
        }}
      >
        {(userIsAccountant || userIsSuperAdmin) && !disableJournalEntryView && row.path?.length === 1 ? (
          <AuthorizedClaims allowedClaims={['Accountant']}>
            <Tooltip title={'View Transactions'}>
              <Link sx={{ cursor: 'pointer' }}>
                <Typography
                  variant={'body2'}
                  fontStyle={row.status === JournalEntryStatus.Pending ? 'italic' : 'normal'}
                  sx={
                    row.status === JournalEntryStatus.Archived
                      ? { color: 'error.main', textDecoration: 'line-through' }
                      : undefined
                  }
                  onClick={() => {
                    row.id && dispatch(getStatementLineDetailByJournalEntryIdAction(row.id));
                    dispatch(dialogChildIsOpenAction(true));
                  }}
                >
                  {value}
                </Typography>
              </Link>
            </Tooltip>
          </AuthorizedClaims>
        ) : (
          <Typography variant={'body2'}>{value}</Typography>
        )}
      </Box>
    </Stack>
  );
};
