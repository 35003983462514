import { EmailTemplateType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export function validateHandlebarKeys(
  allowedKeyType: EmailTemplateType,
  allowedKeys?: { [key: string]: string[] },
  inputString?: string,
): boolean {
  if (!inputString || !allowedKeys) return false;
  const handlebarRegex = /{{\s*([^{}]+?)\s*}}/g;
  let match;

  while ((match = handlebarRegex.exec(inputString)) !== null) {
    const key = match[1].trim();

    if (!allowedKeys[allowedKeyType] || !allowedKeys[allowedKeyType].includes(key)) {
      return false;
    }
  }

  return true;
}

//TODO: flesh out preview data
export const previewData = {
  TenantName: 'John Doe',
};
