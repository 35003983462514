import React, { useEffect } from 'react';
import { ExpandableCard } from '../../../_shared/styledComponents/ExpandableCard';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { NotificationDropdown } from './NotificationDropdown';
import {
  INotificationSetting,
  INotificationSettings,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  updateUserNotificationSettingsAction,
  useUser,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { useDispatch } from 'react-redux';
import { Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import { getAllAssetsAction, useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CamPropertyId } from '../../../../app/constants';

export const Alerts = () => {
  const dispatch = useDispatch();
  const { currentUser } = useUser();
  const { allAssets } = useAssets();
  const propertyClaims = currentUser.value?.claims
    ?.filter(
      (c) =>
        c.type === Claims.BillingOwner ||
        c.type === Claims.Admin ||
        c.type === Claims.OwnerInvestor ||
        c.type === Claims.Employee ||
        c.type === Claims.ExternalVendor ||
        c.type === Claims.PropertyManager,
    )
    .map((c) => c.value);
  const [selectedPropertyId, setSelectedPropertyId] = React.useState<string | undefined>(
    propertyClaims && propertyClaims[1],
  );
  const [assetNames, setAssetNames] = React.useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (!allAssets.value || allAssets.value.length === 0) {
      dispatch(getAllAssetsAction());
    }
    const properties: { [key: string]: string } =
      allAssets.value
        ?.filter((a) => propertyClaims?.includes(a.id!))
        .reduce(
          (acc, a) => {
            acc[a.id!] = a.name!;
            return acc;
          },
          {} as { [key: string]: string },
        ) ?? {};
    setAssetNames(properties);
  }, [allAssets.value]);

  if (currentUser.value?.roles?.includes('SUPERADMIN')) assetNames[CamPropertyId.toLowerCase()] = 'Smart Management';

  const propertyNotificationSettings: INotificationSettings | undefined =
    currentUser.value?.propertyNotificationSettings?.[selectedPropertyId ?? ''];

  const [leaseNotifications, setLeaseNotifications] = React.useState<INotificationSetting>(
    propertyNotificationSettings?.leaseUpdates ?? {},
  );
  const [taskNotifications, setTaskNotifications] = React.useState<INotificationSetting>(
    propertyNotificationSettings?.taskUpdates ?? {},
  );
  const [communicationNotifications, setCommunicationNotifications] = React.useState<INotificationSetting>(
    propertyNotificationSettings?.communicationUpdates ?? {},
  );
  const [applicationNotifications, setApplicationNotifications] = React.useState<INotificationSetting>(
    propertyNotificationSettings?.applicationUpdates ?? {},
  );

  useEffect(() => {
    if (!selectedPropertyId || !currentUser.value?.propertyNotificationSettings) return;
    currentUser.value?.propertyNotificationSettings[selectedPropertyId]?.leaseUpdates &&
      setLeaseNotifications(propertyNotificationSettings?.leaseUpdates ?? {});
    currentUser.value?.propertyNotificationSettings[selectedPropertyId]?.taskUpdates &&
      setTaskNotifications(propertyNotificationSettings?.taskUpdates ?? {});
    currentUser.value?.propertyNotificationSettings[selectedPropertyId]?.communicationUpdates &&
      setCommunicationNotifications(propertyNotificationSettings?.communicationUpdates ?? {});
    currentUser.value?.propertyNotificationSettings[selectedPropertyId]?.applicationUpdates &&
      setCommunicationNotifications(propertyNotificationSettings?.applicationUpdates ?? {});
  }, [selectedPropertyId]);

  const handleUpdateUserAlerts = (
    newNotifications: INotificationSetting,
    setter: (value: INotificationSetting) => void,
  ) => {
    selectedPropertyId &&
      dispatch(
        updateUserNotificationSettingsAction({
          propertyId: selectedPropertyId,
          settings: {
            leaseUpdates: setter === setLeaseNotifications ? newNotifications : leaseNotifications,
            taskUpdates: setter === setTaskNotifications ? newNotifications : taskNotifications,
            communicationUpdates:
              setter === setCommunicationNotifications ? newNotifications : communicationNotifications,
            applicationUpdates: setter === setApplicationNotifications ? newNotifications : applicationNotifications,
          },
        }),
      );
  };

  const updateNotifications = (
    notificationChange: INotificationSetting,
    setter: (value: INotificationSetting) => void,
  ) => {
    setter(notificationChange);
    handleUpdateUserAlerts(notificationChange, setter);
  };

  return (
    <ExpandableCard title={'Notification Settings'} subtitle={''}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <CardContent>
          <Stack spacing={1}>
            <FormControl fullWidth sx={{ mt: '.5rem' }}>
              <InputLabel id={'selected-property-input'}>Property</InputLabel>
              <Select
                id={'selected-property'}
                value={selectedPropertyId ?? ''}
                required
                labelId={'selected-property-input'}
                label={'Status'}
                onChange={(e) => {
                  setSelectedPropertyId(e.target?.value ?? '');
                }}
              >
                {Object.keys(assetNames).map((assetId) => (
                  <MenuItem key={assetId} value={assetId}>
                    {assetNames[assetId]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <NotificationDropdown
              notificationSetting={leaseNotifications}
              setNotificationSetting={(notifications) => updateNotifications(notifications, setLeaseNotifications)}
              label={'Lease Updates'}
            />
            <NotificationDropdown
              notificationSetting={taskNotifications}
              setNotificationSetting={(notifications) => updateNotifications(notifications, setTaskNotifications)}
              label={'Task Updates'}
            />
            <NotificationDropdown
              notificationSetting={communicationNotifications}
              setNotificationSetting={(notifications) =>
                updateNotifications(notifications, setCommunicationNotifications)
              }
              label={'Property Communications'}
            />
            <NotificationDropdown
              notificationSetting={applicationNotifications}
              setNotificationSetting={(notifications) =>
                updateNotifications(notifications, setApplicationNotifications)
              }
              label={'Application Settings'}
            />
          </Stack>
        </CardContent>
      </form>
    </ExpandableCard>
  );
};
